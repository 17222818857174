
let wasm;

const heap = new Array(32).fill(undefined);

heap.push(undefined, null, true, false);

function getObject(idx) { return heap[idx]; }

let heap_next = heap.length;

function dropObject(idx) {
    if (idx < 36) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

const cachedTextDecoder = new TextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachedUint8Memory0 = new Uint8Array();

function getUint8Memory0() {
    if (cachedUint8Memory0.byteLength === 0) {
        cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

let cachedInt32Memory0 = new Int32Array();

function getInt32Memory0() {
    if (cachedInt32Memory0.byteLength === 0) {
        cachedInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachedInt32Memory0;
}

function getCachedStringFromWasm0(ptr, len) {
    if (ptr === 0) {
        return getObject(len);
    } else {
        return getStringFromWasm0(ptr, len);
    }
}
/**
*/
export function run_initializations() {
    wasm.run_initializations();
}

let WASM_VECTOR_LEN = 0;

function passArray8ToWasm0(arg, malloc) {
    const ptr = malloc(arg.length * 1);
    getUint8Memory0().set(arg, ptr / 1);
    WASM_VECTOR_LEN = arg.length;
    return ptr;
}
/**
* @param {Uint8Array} bytes
* @returns {ServerMessageWrapper}
*/
export function parse_message(bytes) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        const ptr0 = passArray8ToWasm0(bytes, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.parse_message(retptr, ptr0, len0);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var r2 = getInt32Memory0()[retptr / 4 + 2];
        if (r2) {
            throw takeObject(r1);
        }
        return ServerMessageWrapper.__wrap(r0);
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

function getArrayU8FromWasm0(ptr, len) {
    return getUint8Memory0().subarray(ptr / 1, ptr / 1 + len);
}
/**
* @param {Uint8Array} cards
* @returns {Uint8Array}
*/
export function create_program_cards_message(cards) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        const ptr0 = passArray8ToWasm0(cards, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.create_program_cards_message(retptr, ptr0, len0);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var v1 = getArrayU8FromWasm0(r0, r1).slice();
        wasm.__wbindgen_free(r0, r1 * 1);
        return v1;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

/**
* @param {Uint8Array} bytes
* @returns {ParsedMap}
*/
export function parse_map(bytes) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        const ptr0 = passArray8ToWasm0(bytes, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.parse_map(retptr, ptr0, len0);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var r2 = getInt32Memory0()[retptr / 4 + 2];
        if (r2) {
            throw takeObject(r1);
        }
        return ParsedMap.__wrap(r0);
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

const cachedTextEncoder = new TextEncoder('utf-8');

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length);
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len);

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3);
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

function handleError(f, args) {
    try {
        return f.apply(this, args);
    } catch (e) {
        wasm.__wbindgen_exn_store(addHeapObject(e));
    }
}

function notDefined(what) { return () => { throw new Error(`${what} is not defined`); }; }
/**
*/
export const RegisterMovePhase = Object.freeze({ PlayerCards:0,"0":"PlayerCards",FastBelts:1,"1":"FastBelts",SlowBelts:2,"2":"SlowBelts",PushPanels:3,"3":"PushPanels",Rotations:4,"4":"Rotations",Lasers:5,"5":"Lasers",Checkpoints:6,"6":"Checkpoints", });
/**
*/
export const Direction = Object.freeze({ Up:0,"0":"Up",Right:1,"1":"Right",Down:2,"2":"Down",Left:3,"3":"Left", });
/**
*/
export const ServerMessageType = Object.freeze({ Notice:0,"0":"Notice",GameLog:1,"1":"GameLog",GeneralState:2,"2":"GeneralState",ProgrammingState:3,"3":"ProgrammingState",AnimatedState:4,"4":"AnimatedState", });

const AnimationItemFinalization = new FinalizationRegistry(ptr => wasm.__wbg_animationitem_free(ptr));
/**
*/
export class AnimationItem {

    static __wrap(ptr) {
        const obj = Object.create(AnimationItem.prototype);
        obj.ptr = ptr;
        AnimationItemFinalization.register(obj, obj.ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;
        AnimationItemFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_animationitem_free(ptr);
    }
    /**
    * @param {(from: Position, to: Position, direction: Direction, is_from_tank: boolean) => void} process_bullet_closure
    * @param {(player_i: number) => void} process_checkpoint_visited_closure
    * @param {(player_i: number, direction: Direction) => void} process_attempted_move_closure
    */
    process_animations(process_bullet_closure, process_checkpoint_visited_closure, process_attempted_move_closure) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.animationitem_process_animations(retptr, this.ptr, addHeapObject(process_bullet_closure), addHeapObject(process_checkpoint_visited_closure), addHeapObject(process_attempted_move_closure));
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            if (r1) {
                throw takeObject(r0);
            }
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {boolean}
    */
    get has_state() {
        const ret = wasm.animationitem_has_state(this.ptr);
        return ret !== 0;
    }
    /**
    * @returns {number}
    */
    get register() {
        const ret = wasm.animationitem_register(this.ptr);
        return ret >>> 0;
    }
    /**
    * @returns {number}
    */
    get register_phase() {
        const ret = wasm.animationitem_register_phase(this.ptr);
        return ret >>> 0;
    }
    /**
    * @returns {Uint8Array}
    */
    get my_cards() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.animationitem_my_cards(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v0 = getArrayU8FromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 1);
            return v0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Array<PlayerPublicState>}
    */
    get player_states() {
        const ret = wasm.animationitem_player_states(this.ptr);
        return takeObject(ret);
    }
    /**
    * @param {number} player_i
    * @returns {Uint8Array}
    */
    get_revealed_cards(player_i) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.animationitem_get_revealed_cards(retptr, this.ptr, player_i);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v0 = getArrayU8FromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 1);
            return v0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const AssetFinalization = new FinalizationRegistry(ptr => wasm.__wbg_asset_free(ptr));
/**
*/
export class Asset {

    static __wrap(ptr) {
        const obj = Object.create(Asset.prototype);
        obj.ptr = ptr;
        AssetFinalization.register(obj, obj.ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;
        AssetFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_asset_free(ptr);
    }
    /**
    * @returns {string}
    */
    get value() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.asset_value(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v0 = getCachedStringFromWasm0(r0, r1);
        if (r0 !== 0) { wasm.__wbindgen_free(r0, r1); }
        return v0;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}
/**
* @returns {string}
*/
get style() {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.asset_style(retptr, this.ptr);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var v0 = getCachedStringFromWasm0(r0, r1);
    if (r0 !== 0) { wasm.__wbindgen_free(r0, r1); }
    return v0;
} finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
}
}
/**
* @returns {boolean}
*/
get is_text() {
    const ret = wasm.asset_is_text(this.ptr);
    return ret !== 0;
}
}

const AssetMapFinalization = new FinalizationRegistry(ptr => wasm.__wbg_assetmap_free(ptr));
/**
*/
export class AssetMap {

    static __wrap(ptr) {
        const obj = Object.create(AssetMap.prototype);
        obj.ptr = ptr;
        AssetMapFinalization.register(obj, obj.ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;
        AssetMapFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_assetmap_free(ptr);
    }
    /**
    * @returns {number}
    */
    get checkpoints() {
        const ret = wasm.__wbg_get_assetmap_checkpoints(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} x
    * @param {number} y
    * @returns {TileAssets}
    */
    get(x, y) {
        const ret = wasm.assetmap_get(this.ptr, x, y);
        return TileAssets.__wrap(ret);
    }
    /**
    * @returns {number}
    */
    get width() {
        const ret = wasm.assetmap_width(this.ptr);
        return ret;
    }
    /**
    * @returns {number}
    */
    get height() {
        const ret = wasm.assetmap_height(this.ptr);
        return ret;
    }
}

const GeneralStateFinalization = new FinalizationRegistry(ptr => wasm.__wbg_generalstate_free(ptr));
/**
* Player's view of the game - doesn't inlude other players' cards etc.
*/
export class GeneralState {

    static __wrap(ptr) {
        const obj = Object.create(GeneralState.prototype);
        obj.ptr = ptr;
        GeneralStateFinalization.register(obj, obj.ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;
        GeneralStateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_generalstate_free(ptr);
    }
    /**
    * @param {number} player_i
    * @returns {string}
    */
    get_player_name(player_i) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.generalstate_get_player_name(retptr, this.ptr, player_i);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v0 = getCachedStringFromWasm0(r0, r1);
        if (r0 !== 0) { wasm.__wbindgen_free(r0, r1); }
        return v0;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}
/**
* @returns {string}
*/
get status() {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.generalstate_status(retptr, this.ptr);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var v0 = getCachedStringFromWasm0(r0, r1);
    if (r0 !== 0) { wasm.__wbindgen_free(r0, r1); }
    return v0;
} finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
}
}
}

const ParsedMapFinalization = new FinalizationRegistry(ptr => wasm.__wbg_parsedmap_free(ptr));
/**
*/
export class ParsedMap {

    static __wrap(ptr) {
        const obj = Object.create(ParsedMap.prototype);
        obj.ptr = ptr;
        ParsedMapFinalization.register(obj, obj.ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;
        ParsedMapFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_parsedmap_free(ptr);
    }
    /**
    * @returns {AssetMap}
    */
    get assets() {
        const ret = wasm.parsedmap_assets(this.ptr);
        return AssetMap.__wrap(ret);
    }
    /**
    * Used to render a map preview
    *
    * A specific asset for "Spawnpoint" doesn't exist, so for map preview, this method creates an artificial
    * state with a robot named "Spawnpoint" at each spawnpoint location
    * @returns {Array<PlayerPublicState>}
    */
    get_artificial_spawn_state() {
        const ret = wasm.parsedmap_get_artificial_spawn_state(this.ptr);
        return takeObject(ret);
    }
}

const PlayerPublicStateFinalization = new FinalizationRegistry(ptr => wasm.__wbg_playerpublicstate_free(ptr));
/**
* Public state of 1 player
*/
export class PlayerPublicState {

    static __wrap(ptr) {
        const obj = Object.create(PlayerPublicState.prototype);
        obj.ptr = ptr;
        PlayerPublicStateFinalization.register(obj, obj.ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;
        PlayerPublicStateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_playerpublicstate_free(ptr);
    }
    /**
    * @returns {Position}
    */
    get position() {
        const ret = wasm.playerpublicstate_position(this.ptr);
        return Position.__wrap(ret);
    }
    /**
    * Note: doesn't include transform to current tile
    * @returns {string}
    */
    get style() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.playerpublicstate_style(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v0 = getCachedStringFromWasm0(r0, r1);
        if (r0 !== 0) { wasm.__wbindgen_free(r0, r1); }
        return v0;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}
/**
* @returns {number}
*/
get checkpoint() {
    const ret = wasm.playerpublicstate_checkpoint(this.ptr);
    return ret >>> 0;
}
/**
* @returns {boolean}
*/
get is_rebooting() {
    const ret = wasm.playerpublicstate_is_rebooting(this.ptr);
    return ret !== 0;
}
/**
* @returns {boolean}
*/
get is_hidden() {
    const ret = wasm.playerpublicstate_is_hidden(this.ptr);
    return ret !== 0;
}
}

const PositionFinalization = new FinalizationRegistry(ptr => wasm.__wbg_position_free(ptr));
/**
* (0,0) &rarr; +x  \
* &darr;  \
* +y
*/
export class Position {

    static __wrap(ptr) {
        const obj = Object.create(Position.prototype);
        obj.ptr = ptr;
        PositionFinalization.register(obj, obj.ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;
        PositionFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_position_free(ptr);
    }
    /**
    * @returns {number}
    */
    get x() {
        const ret = wasm.__wbg_get_position_x(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set x(arg0) {
        wasm.__wbg_set_position_x(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get y() {
        const ret = wasm.__wbg_get_position_y(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set y(arg0) {
        wasm.__wbg_set_position_y(this.ptr, arg0);
    }
}

const ProgrammingStateFinalization = new FinalizationRegistry(ptr => wasm.__wbg_programmingstate_free(ptr));
/**
*/
export class ProgrammingState {

    static __wrap(ptr) {
        const obj = Object.create(ProgrammingState.prototype);
        obj.ptr = ptr;
        ProgrammingStateFinalization.register(obj, obj.ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;
        ProgrammingStateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_programmingstate_free(ptr);
    }
    /**
    * @returns {Uint8Array}
    */
    get hand() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.programmingstate_hand(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v0 = getArrayU8FromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 1);
            return v0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Uint8Array | undefined}
    */
    get prepared_cards() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.programmingstate_prepared_cards(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            let v0;
            if (r0 !== 0) {
                v0 = getArrayU8FromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1);
            }
            return v0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Uint8Array}
    */
    get ready_players() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.programmingstate_ready_players(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v0 = getArrayU8FromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 1);
            return v0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Array<PlayerPublicState>}
    */
    get player_states() {
        const ret = wasm.programmingstate_player_states(this.ptr);
        return takeObject(ret);
    }
}

const RunningStateViewFinalization = new FinalizationRegistry(ptr => wasm.__wbg_runningstateview_free(ptr));
/**
* Player's view of the game - doesn't inlude other players' cards etc.
*/
export class RunningStateView {

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;
        RunningStateViewFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_runningstateview_free(ptr);
    }
}

const ServerMessageWrapperFinalization = new FinalizationRegistry(ptr => wasm.__wbg_servermessagewrapper_free(ptr));
/**
*/
export class ServerMessageWrapper {

    static __wrap(ptr) {
        const obj = Object.create(ServerMessageWrapper.prototype);
        obj.ptr = ptr;
        ServerMessageWrapperFinalization.register(obj, obj.ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;
        ServerMessageWrapperFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_servermessagewrapper_free(ptr);
    }
    /**
    * @returns {number}
    */
    get typ() {
        const ret = wasm.servermessagewrapper_typ(this.ptr);
        return ret >>> 0;
    }
    /**
    * @returns {string}
    */
    get notice() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.servermessagewrapper_notice(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v0 = getCachedStringFromWasm0(r0, r1);
        if (r0 !== 0) { wasm.__wbindgen_free(r0, r1); }
        return v0;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}
/**
* @returns {string}
*/
get game_log() {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.servermessagewrapper_game_log(retptr, this.ptr);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var v0 = getCachedStringFromWasm0(r0, r1);
    if (r0 !== 0) { wasm.__wbindgen_free(r0, r1); }
    return v0;
} finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
}
}
/**
* @returns {GeneralState}
*/
get general_state() {
    const ret = wasm.servermessagewrapper_general_state(this.ptr);
    return GeneralState.__wrap(ret);
}
/**
* @returns {ProgrammingState}
*/
get programming_state() {
    const ret = wasm.servermessagewrapper_programming_state(this.ptr);
    return ProgrammingState.__wrap(ret);
}
/**
* @returns {AnimationItem}
*/
get animated_state() {
    const ret = wasm.servermessagewrapper_animated_state(this.ptr);
    return AnimationItem.__wrap(ret);
}
}

const TileAssetsFinalization = new FinalizationRegistry(ptr => wasm.__wbg_tileassets_free(ptr));
/**
*/
export class TileAssets {

    static __wrap(ptr) {
        const obj = Object.create(TileAssets.prototype);
        obj.ptr = ptr;
        TileAssetsFinalization.register(obj, obj.ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;
        TileAssetsFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_tileassets_free(ptr);
    }
    /**
    * @returns {Array<Asset>}
    */
    into_jsarray() {
        const ptr = this.__destroy_into_raw();
        const ret = wasm.tileassets_into_jsarray(ptr);
        return takeObject(ret);
    }
}

async function load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

function getImports() {
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbg_asset_new = function(arg0) {
        const ret = Asset.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_object_drop_ref = function(arg0) {
        takeObject(arg0);
    };
    imports.wbg.__wbg_new_abda76e883ba8a5f = function() {
        const ret = new Error();
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_stack_658279fe44541cf6 = function(arg0, arg1) {
        const ret = getObject(arg1).stack;
        const ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    };
    imports.wbg.__wbg_error_f851667af71bcfc6 = function(arg0, arg1) {
        var v0 = getCachedStringFromWasm0(arg0, arg1);
    if (arg0 !== 0) { wasm.__wbindgen_free(arg0, arg1); }
    console.error(v0);
};
imports.wbg.__wbindgen_number_new = function(arg0) {
    const ret = arg0;
    return addHeapObject(ret);
};
imports.wbg.__wbg_position_new = function(arg0) {
    const ret = Position.__wrap(arg0);
    return addHeapObject(ret);
};
imports.wbg.__wbg_playerpublicstate_new = function(arg0) {
    const ret = PlayerPublicState.__wrap(arg0);
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_string_new = function(arg0, arg1) {
    const ret = getStringFromWasm0(arg0, arg1);
    return addHeapObject(ret);
};
imports.wbg.__wbg_debug_f15cb542ea509609 = function(arg0) {
    console.debug(getObject(arg0));
};
imports.wbg.__wbg_error_ef9a0be47931175f = function(arg0) {
    console.error(getObject(arg0));
};
imports.wbg.__wbg_info_2874fdd5393f35ce = function(arg0) {
    console.info(getObject(arg0));
};
imports.wbg.__wbg_log_4b5638ad60bdc54a = function(arg0) {
    console.log(getObject(arg0));
};
imports.wbg.__wbg_warn_58110c4a199df084 = function(arg0) {
    console.warn(getObject(arg0));
};
imports.wbg.__wbg_new_1d9a920c6bfc44a8 = function() {
    const ret = new Array();
    return addHeapObject(ret);
};
imports.wbg.__wbg_push_740e4b286702d964 = function(arg0, arg1) {
    const ret = getObject(arg0).push(getObject(arg1));
    return ret;
};
imports.wbg.__wbg_apply_22a3220d244124da = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = getObject(arg0).apply(getObject(arg1), getObject(arg2));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_call_168da88779e35f61 = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = getObject(arg0).call(getObject(arg1), getObject(arg2));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_call_3999bee59e9f7719 = function() { return handleError(function (arg0, arg1, arg2, arg3) {
    const ret = getObject(arg0).call(getObject(arg1), getObject(arg2), getObject(arg3));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_now_58886682b7e790d7 = function() {
    const ret = Date.now();
    return ret;
};
imports.wbg.__wbg_random_656f2ae924b2540e = typeof Math.random == 'function' ? Math.random : notDefined('Math.random');
imports.wbg.__wbindgen_throw = function(arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
};

return imports;
}

function initMemory(imports, maybe_memory) {

}

function finalizeInit(instance, module) {
    wasm = instance.exports;
    init.__wbindgen_wasm_module = module;
    cachedInt32Memory0 = new Int32Array();
    cachedUint8Memory0 = new Uint8Array();

    wasm.__wbindgen_start();
    return wasm;
}

function initSync(module) {
    const imports = getImports();

    initMemory(imports);

    if (!(module instanceof WebAssembly.Module)) {
        module = new WebAssembly.Module(module);
    }

    const instance = new WebAssembly.Instance(module, imports);

    return finalizeInit(instance, module);
}

async function init(input) {
    if (typeof input === 'undefined') {
        input = new URL('roborally_frontend_wasm_bg.wasm', import.meta.url);
    }
    const imports = getImports();

    if (typeof input === 'string' || (typeof Request === 'function' && input instanceof Request) || (typeof URL === 'function' && input instanceof URL)) {
        input = fetch(input);
    }

    initMemory(imports);

    const { instance, module } = await load(await input, imports);

    return finalizeInit(instance, module);
}

export { initSync }
export default init;
